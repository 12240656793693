// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset css */
html {
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: "Public Sans", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	color: #1F3326;
}

* {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

input,
textarea,
select,
button {
	outline: none;
	word-wrap: normal;
}

input {
	line-height: normal;
}

label,
button {
	cursor: pointer;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
}

::-webkit-scrollbar {
	width: 2px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #1193D4;
	border-radius: 100rem;
}

/* Example styles */
.uk-container {
  max-width: 600px;
  margin: 0 auto;
}

.uk-heading-small {
  text-align: center;
  margin-top: 20px;
}

.uk-button {
  display: block;
  width: 100%;
  margin-top: 20px;
}

.uk-alert-danger {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA,cAAc;AACd;CACC,sBAAsB;CACtB,uBAAuB;CACvB,sCAAsC;CACtC,yBAAyB;CACzB,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,SAAS;CACT,UAAU;AACX;;AAEA;;;CAGC,sBAAsB;AACvB;;AAEA;;;;CAIC,aAAa;CACb,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;;CAEC,eAAe;AAChB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,mBAAmB;CACnB,qBAAqB;AACtB;;AAEA,mBAAmB;AACnB;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');\n\n/* Reset css */\nhtml {\n\tbox-sizing: border-box;\n\tscroll-behavior: smooth;\n\tfont-family: \"Public Sans\", sans-serif;\n\tfont-optical-sizing: auto;\n\tfont-style: normal;\n\tcolor: #1F3326;\n}\n\n* {\n\tmargin: 0;\n\tpadding: 0;\n}\n\n*,\n*:before,\n*:after {\n\tbox-sizing: border-box;\n}\n\ninput,\ntextarea,\nselect,\nbutton {\n\toutline: none;\n\tword-wrap: normal;\n}\n\ninput {\n\tline-height: normal;\n}\n\nlabel,\nbutton {\n\tcursor: pointer;\n}\n\na {\n\ttext-decoration: none;\n}\n\nimg {\n\tdisplay: block;\n\tmax-width: 100%;\n}\n\n::-webkit-scrollbar {\n\twidth: 2px;\n}\n\n::-webkit-scrollbar-track {\n\tbackground: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n\tbackground: #1193D4;\n\tborder-radius: 100rem;\n}\n\n/* Example styles */\n.uk-container {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.uk-heading-small {\n  text-align: center;\n  margin-top: 20px;\n}\n\n.uk-button {\n  display: block;\n  width: 100%;\n  margin-top: 20px;\n}\n\n.uk-alert-danger {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
